import './JourneyDetailView.css';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {fetchJourney, createJourney, updateJourney} from '../../api';

import {useNavigate, useParams} from 'react-router-dom';
import {Journey} from '../../types/Journey';
import {DailyFrequencySection} from './DailyFrequencySection';
import {
    Button,
    ButtonGroup,
    Col,
    Container, Form,
    FormCheck,
    FormControl,
    FormLabel,
    FormSelect,
    Row
} from 'react-bootstrap';
import {APIsView} from './APIsView';
import {TriggersView} from './TriggersView';
import {Trigger} from '../../types/Trigger';
import {OverlayToast} from '../OverlayToast/OverlayToast';
import {ConditionsView} from './ConditionsView/ConditionsView';
import {RecipientCondition} from '../../types/RecipientCondition';
import {anyDailyFrequencySet, formatDateString, getDayDataFromJourney} from '../../util/dateUtils';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {BackButton} from '../BackButton/BackButton';
import {useMsal} from '@azure/msal-react';
import {DayData} from '../../types/DayData';
import {HorizontallyCenteredSpinner} from '../util/HorizontallyCenteredSpinner';
import {isNumeric} from '../../util/stringUtils';

interface Props {
    setJourneyCreationSuccess:  React.Dispatch<React.SetStateAction<boolean>>;
    setJourneyUpdateSuccess:  React.Dispatch<React.SetStateAction<boolean>>;
}
export const JourneyDetailView = (props: Props) => {

    const {instance} = useMsal();

    const userAccount = instance.getActiveAccount();

    const [journey, setJourney] = useState<Journey>({
        creationDate: new Date(),
        journeyDescription: '',
        journeyAuthor: {
            entraUsername: '',
            name: '',
            role: 'SmsRead'
        },
        journeyEnd: new Date(),
        journeyGuid: '',
        journeyId: 0,
        journeyStart: new Date(),
        replyEmails: [],
        deferToWeekend: false,
        messageMedium: 'Sms',
        messages: [],
        triggers: [],
        recipientConditions: [],
        status: 'Paused',
        journeyName: '',
        messageBody: ''
    });

    const {guid} = useParams();

    const [days, setDays] = useState<DayData[]>(getDayDataFromJourney(journey));

    const [durationType, setDurationType] = useState<'Continuous' | 'Scheduled'>('Continuous');
    const [dailyFrequencyOn, setDailyFrequencyOn] = useState(false);
    
    // toasts
    const [showFailure, setShowFailure] = useState(false);
    const [showValidationFailure, setShowValidationFailure] = useState(false);
    const [validationFailureHeader, setValidationFailureHeader] = useState('Validation Failure');
    const [validationFailureBody, setValidationFailureBody] = useState('Validation Failure');
    
    const [loading, setLoading] = useState(!!guid);
    const [updating, setUpdating] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false);

    const navigate = useNavigate();

    const smsBoxClassname = journey.messageMedium === 'Sms' ? 'sms-box' : 'sms-box disabled';
    const emailBoxClassname = journey.messageMedium === 'Email' ? 'email-box' : 'email-box disabled';

    const dailyFrequencyOnButtonStyle = dailyFrequencyOn ? 'daily-frequency-button-enabled' : 'daily-frequency-button-disabled';
    const dailyFrequencyOffButtonStyle = !dailyFrequencyOn ? 'daily-frequency-button-enabled' : 'daily-frequency-button-disabled';


    useEffect(() => {
        if (guid) {
            const fetchJourneyAsync = async () => {

                try {
                    const response = await fetchJourney(guid ?? '');
                    if (response.status === 200) {
                        setJourney(response.data);

                        const journey = response.data as Journey;
                        if (journey.journeyStart && journey.journeyEnd) {
                            setDurationType('Scheduled');
                        }

                        if (anyDailyFrequencySet(journey)) {
                            setDailyFrequencyOn(true);
                        }

                        setDays(getDayDataFromJourney(journey));
                        setLoading(false);
                    }
                } catch (err) {
                    setShowFailure(true);
                    window.setTimeout(() => setShowFailure(false), 5000);
                    navigate('/sms');
                }

            };

            fetchJourneyAsync();
        } else {
            // only attach current user data if creating a new journey
            // otherwise we are attaching user data of the author
            const authorUserName = userAccount?.name ?? '';
            const authorEntraUserName = userAccount?.username ?? '';

            setJourney({
                ...journey, journeyAuthor: {
                    name: authorUserName,
                    entraUsername: authorEntraUserName,
                    role: 'SmsRead'
                }
            });
        }
    }, [guid]);

    const handleMessageMediumChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newJourney = {...journey};
        if (event.target.value === 'SMS') {
            newJourney.messageMedium = 'Sms';
        } else {
            newJourney.messageMedium = 'Email';
        }
        setJourney(newJourney);
    };

    const handleDurationTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setDurationType(event.target.value as 'Scheduled' | 'Continuous');
        if (event.target.value === 'Continuous') {
            setJourney((previousJourney) => {
                return {...previousJourney, journeyStart: null, journeyEnd: null};
            });
        }
        if (event.target.value === 'Scheduled') {
            setJourney((previousJourney) => {
                return {...previousJourney, journeyStart: new Date(), journeyEnd: new Date()};
            });
        }
    };

    const handleAddTrigger = () => {
        const newJourney = {...journey};
        const newTrigger: Trigger = {
            timeUnit: 'days',
            time: 30,
            after: true,
            api: 'placementStart',
            onDate: undefined
        };
        newJourney.triggers.push(newTrigger);
        setJourney(newJourney);
    };

    const handleRemoveTrigger = (index: number) => {
        const newJourney = {...journey};
        newJourney.triggers.splice(index, 1);
        setJourney(newJourney);
    };

    const handleChangeTrigger = (index: number, newTrigger: Trigger) => {
        const newJourney = {...journey};
        newJourney.triggers[index] = newTrigger;
        setJourney(newJourney);
    };

    const handleAddCondition = () => {
        const newJourney = {...journey};
        const newCondition: RecipientCondition = {
            apiName: undefined,
            apiComparator: '=',
            apiValue: new Date()
        };
        newJourney.recipientConditions.push(newCondition);
        setJourney(newJourney);
    };

    const handleRemoveCondition = (index: number) => {
        const newJourney = {...journey};
        newJourney.recipientConditions.splice(index, 1);
        setJourney(newJourney);
    };

    const handleChangeCondition = (index: number, condition: RecipientCondition) => {
        const newJourney = {...journey};
        newJourney.recipientConditions[index] = condition;
        setJourney(newJourney);
    };

    const validateJourney = (journey: Journey) => {
        setSubmitAttempted(true);
        
        if (journey.journeyName.trim() === '') {
            setShowValidationFailure(true);
            setValidationFailureHeader('Missing Required Field');
            setValidationFailureBody('Journey Name Not Specified');
            window.setTimeout(() => setShowValidationFailure(false), 5000);
            setUpdating(false);
            return false;
        }

        if (journey.journeyDescription.trim() === '') {
            setShowValidationFailure(true);
            setValidationFailureHeader('Missing Required Field');
            setValidationFailureBody('Journey Description Not Specified');
            window.setTimeout(() => setShowValidationFailure(false), 5000);
            setUpdating(false);
            return false;
        }

        if (journey.messageBody.trim() === '') {
            setShowValidationFailure(true);
            setValidationFailureHeader('Missing Required Field');
            setValidationFailureBody('SMS Message Body Not Specified');
            window.setTimeout(() => setShowValidationFailure(false), 5000);
            setUpdating(false);
            return false;
        }

        if (journey.recipientConditions.some(c => c.apiName === undefined)) {
            setShowValidationFailure(true);
            setValidationFailureHeader('Invalid Selection Condition');
            setValidationFailureBody('Selection Condition Field Not Chosen');
            window.setTimeout(() => setShowValidationFailure(false), 5000);
            setUpdating(false);
            return false;
        }

        if (journey.recipientConditions.some(c => [
            'tempAddressLastUpdate',
            'placementStart',
            'placementEnd',
            'contactCreatedDate',
            'contactLastActivityByRecruiter'].includes(c.apiName!) && !isNumeric(c.apiValue as string) && c.apiValue !== 'null')) {
            setShowValidationFailure(true);
            setValidationFailureHeader('Invalid Selection Condition');
            setValidationFailureBody('Selection Condition Field Must be a Number, or "null"');
            window.setTimeout(() => setShowValidationFailure(false), 5000);
            setUpdating(false);
            return false;
        }
        
        return true;
    };
    
    const handleAddButtonClicked = () => {
        setUpdating(true);
        const createJourneyAsync = async () => {

            if (journey === undefined || !validateJourney(journey)) {
                return;
            }

            try {
                const response = await createJourney(journey);
                if (response.status === 204) {
                    setUpdating(false);
                    props.setJourneyCreationSuccess(true);
                    window.setTimeout(() => props.setJourneyCreationSuccess(false), 5000);
                    navigate('/sms');
                }
            } catch (err) {
                setUpdating(false);
                setShowFailure(true);
                window.setTimeout(() => setShowFailure(false), 5000);
            }

        };

        createJourneyAsync();
    };

    const handleUpdateJourney = () => {
        setUpdating(true);

        const updateJourneyAsync = async () => {

            if (journey === undefined || !validateJourney(journey)) {
                return;
            }

            try {
                const response = await updateJourney(journey);
                if (response.status === 204) {
                    setUpdating(false);
                    props.setJourneyUpdateSuccess(true);
                    window.setTimeout(() => props.setJourneyUpdateSuccess(false), 5000);
                    navigate('/sms');
                }
            } catch (err) {
                setUpdating(false);
                setShowFailure(true);
                window.setTimeout(() => setShowFailure(false), 5000);
            }

        };

        updateJourneyAsync();
    };

    const handleJourneyStartChange = (event: ChangeEvent<HTMLInputElement>) => {
        const startDate = new Date(event.target.value);
        const newJourney = {...journey, journeyStart: startDate};
        setJourney(newJourney);
    };

    const handleJourneyEndChange = (event: ChangeEvent<HTMLInputElement>) => {
        const endDate = new Date(event.target.value);
        const newJourney = {...journey, journeyEnd: endDate};
        setJourney(newJourney);
    };

    const handleReplyEmailsChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newEmailsList = event.target.value.split(',');
        for (let i = 0; i < newEmailsList.length; i++) {
            newEmailsList[i] = newEmailsList[i].trim();
        }
        const newJourney = {...journey, replyEmails: newEmailsList};
        setJourney(newJourney);
    };

    const handleDeferToWeekendChange = () => {
        const newJourney = {...journey};
        newJourney.deferToWeekend = !newJourney.deferToWeekend;
        newJourney.saturdayStartTime = '00:00:00';
        newJourney.saturdayEndTime = '00:00:00';
        newJourney.sundayStartTime = '00:00:00';
        newJourney.sundayEndTime = '00:00:00';
        setJourney(newJourney);
        const newDays = [...days];
        newDays.find(d => d.dayName === 'SATURDAY')!.active = false;
        newDays.find(d => d.dayName === 'SUNDAY')!.active = false;
        setDays(newDays);
    };

    const handleCopyToken = (token: string) => {
        const newJourney = {...journey};
        if (newJourney.messageBody.at(newJourney.messageBody.length - 1) !== ' ' && newJourney.messageBody.length > 0) {
            newJourney.messageBody = `${newJourney.messageBody} ${token}`;
        } else {
            newJourney.messageBody = `${newJourney.messageBody}${token}`;
        }
        setJourney(newJourney);
    };

    const handleDailyFrequencyChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.currentTarget.value === 'on') {
            setDailyFrequencyOn(true);
        }
        if (event.currentTarget.value === 'off') {
            const newJourney = {...journey};
            newJourney.mondayStartTime = newJourney.mondayEndTime = newJourney.tuesdayStartTime = newJourney.tuesdayEndTime =
                newJourney.wednesdayStartTime = newJourney.wednesdayEndTime = newJourney.thursdayStartTime = newJourney.thursdayEndTime =
                    newJourney.fridayStartTime = newJourney.fridayEndTime = newJourney.saturdayStartTime = newJourney.saturdayEndTime =
                        newJourney.sundayStartTime = newJourney.sundayEndTime = '00:00:00';

            setJourney(newJourney);
            setDailyFrequencyOn(false);
        }
    };

    return (
        <div id="container">
            {
                loading ?
                    <>
                        <Row md={'auto'} className={'align-items-center'}>
                            <Col>
                                <BackButton/>
                            </Col>
                        </Row>
                        <Row><HorizontallyCenteredSpinner showLoadingText={true}/></Row>
                    </>
                    :
                    <>
                        <Row md={'auto'} className={'align-items-center'}>
                            <Col>
                                <BackButton/>
                            </Col>
                            <Col>
                                {
                                    guid ?
                                        <h1 className={'max-header'}>EDIT JOURNEY</h1>
                                        :
                                        <h1 className={'max-header'}>NEW JOURNEY</h1>
                                }
                            </Col>
                        </Row>
                        <div>
                            <h2>JOURNEY INFORMATION</h2>
                            <div className="whitebox">
                                <div>
                                    <label style={{margin: 0}}>Journey Name*</label>
                                    <FormControl as={'textarea'} rows={1} value={journey.journeyName}
                                                 isInvalid={journey.journeyName.trim() === '' && submitAttempted}
                                                 onChange={(e) => setJourney((prevJourney) => {
                                                     return {...prevJourney, journeyName: e.target.value};
                                                 })}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a journey name.
                                    </Form.Control.Feedback>
                                </div>
                                <div>
                                    <label style={{margin: 0}}>Description*</label>
                                    <FormControl as={'textarea'} rows={1} value={journey.journeyDescription}
                                                 isInvalid={journey.journeyDescription.trim() === '' && submitAttempted}
                                                 onChange={(e) => setJourney((prevJourney) => {
                                                     return {...prevJourney, journeyDescription: e.target.value};
                                                 })}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a journey description.
                                    </Form.Control.Feedback>
                                </div>
                                <div className="duration-row">
                                    <div>
                                        <label className={'form-label'}>Duration*</label>
                                        <FormSelect value={durationType} onChange={handleDurationTypeChange}>
                                            <option value={'Continuous'}>Continuous</option>
                                            <option value={'Scheduled'}>Scheduled</option>
                                        </FormSelect>
                                    </div>
                                    {
                                        durationType === 'Scheduled' ?
                                            <>
                                                <div>
                                                    <FormLabel>Journey Start*</FormLabel>
                                                    <FormControl
                                                        type={'date'}
                                                        value={formatDateString(journey.journeyStart)}
                                                        onChange={handleJourneyStartChange}/>
                                                </div>
                                                <div>
                                                    <FormLabel>Journey End*</FormLabel>
                                                    <FormControl
                                                        type={'date'}
                                                        value={formatDateString(journey.journeyEnd)}
                                                        onChange={handleJourneyEndChange}/>
                                                </div>
                                            </> : null
                                    }
                                    <div>
                                        <label className={'form-label'}>Daily Frequency</label>
                                        <ButtonGroup>
                                            <Button
                                                value={'on'}
                                                className={dailyFrequencyOnButtonStyle}
                                                onClick={handleDailyFrequencyChange}
                                            >
                                                ON
                                            </Button>
                                            <Button
                                                value={'off'}
                                                className={dailyFrequencyOffButtonStyle}
                                                onClick={handleDailyFrequencyChange}
                                            >
                                                OFF
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                    <div>
                                        <label className={'form-label'}>Reply Forwarding Emails</label>
                                        <FormControl as={'textarea'} value={journey.replyEmails?.join(',') ?? ''}
                                                     rows={1}
                                                     onChange={handleReplyEmailsChange}/>
                                        <small>Include all emails to be notified when a user replies to this journey,
                                            separated by a
                                            comma.</small>
                                    </div>
                                </div>
                                <FormCheck type={'checkbox'}>
                                    <FormCheckInput onChange={handleDeferToWeekendChange}
                                                    checked={journey.deferToWeekend}/>
                                    <FormCheckLabel className={'form-checkbox-label'}>If this journey is triggered on a
                                        weekend,
                                        defer messages to the following Monday.</FormCheckLabel>
                                </FormCheck>
                            </div>
                        </div>

                        {dailyFrequencyOn ?
                            <DailyFrequencySection journey={journey} setJourney={setJourney} dayData={days}
                                                   setDayData={setDays}/> : null}

                        <div>
                            <h2>TECHNICAL INFORMATION</h2>
                            <div className="whitebox">
                                <TriggersView
                                    triggers={journey.triggers}
                                    addTriggerCallback={handleAddTrigger}
                                    removeTriggerCallback={handleRemoveTrigger}
                                    triggerChangeCallback={handleChangeTrigger}
                                />
                                <ConditionsView
                                    conditions={journey.recipientConditions}
                                    addConditionCallback={handleAddCondition}
                                    conditionChangeCallback={handleChangeCondition}
                                    removeConditionCallback={handleRemoveCondition}
                                    submitAttempted={submitAttempted}
                                />
                                <APIsView copyTokenCallback={handleCopyToken}/>
                            </div>
                        </div>
                        <div>
                            <h2>OUTGOING MESSAGE</h2>
                            <div className="whitebox-row">
                                <div className={smsBoxClassname} style={{width: '100%'}}>
                                    <FormCheck
                                        type={'radio'}
                                        label={'SMS'}
                                        value={'SMS'}
                                        onChange={handleMessageMediumChange}
                                        checked={journey.messageMedium === 'Sms'}
                                    />
                                    <label>SMS Message Body*</label>
                                    <FormControl
                                        as={'textarea'}
                                        value={journey.messageMedium === 'Sms' ? journey.messageBody : ''}
                                        isInvalid={journey.messageBody.trim() === '' && submitAttempted}
                                        disabled={journey.messageMedium !== 'Sms'}
                                        onChange={(e) => setJourney((prevJourney) => {
                                            return {...prevJourney, messageBody: e.target.value};
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a message body.
                                    </Form.Control.Feedback>
                                </div>
                                <div className={emailBoxClassname} style={{width: '100%'}}>
                                    <FormCheck
                                        type={'radio'}
                                        label={'Email'}
                                        value={'Email'}
                                        onChange={handleMessageMediumChange}
                                        checked={journey.messageMedium === 'Email'}
                                    />
                                    <label>Email Message Body*</label>
                                    <FormControl
                                        as={'textarea'}
                                        value={journey.messageMedium === 'Email' ? journey.messageBody : ''}
                                        disabled={journey.messageMedium !== 'Email'}
                                        onChange={(e) => setJourney((prevJourney) => {
                                            return {...prevJourney, messageBody: e.target.value};
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            updating ?
                                <HorizontallyCenteredSpinner/>
                                :
                                <Container fluid={'md'} className={'centered'}>
                                    {guid ?
                                        <Button className={'submitButton'} onClick={handleUpdateJourney}>UPDATE JOURNEY</Button>
                                        :
                                        <Button className="submitButton" onClick={handleAddButtonClicked}>SUBMIT NEW
                                            JOURNEY</Button>
                                    }
                                    <Button className={'cancel-button'} variant={'danger'}
                                            onClick={() => navigate('/sms')}>CANCEL</Button>
                                </Container>
                        }
                        <OverlayToast show={showFailure} headerText={'Error'} bodyText={'Something went wrong.'}
                                      bg={'danger'}/>
                        <OverlayToast show={showValidationFailure} 
                                      headerText={validationFailureHeader} 
                                      bodyText={validationFailureBody} 
                                      bg={'danger'} />
                    </>
            }
        </div>

    );
};

