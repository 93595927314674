import {Button, CloseButton, Col, Container, Form, FormSelect, Row} from 'react-bootstrap';
import {RecipientCondition} from '../../../types/RecipientCondition';
import {ChangeEvent} from 'react';
import {acceptableApiNames, ApiConditionTarget} from '../../../types/ApiConditionTarget';
import {ApiComparators, DateComparators} from '../../../types/ApiComparators';
import {ConditionInputBox} from './ConditionInputBox';
import {ConditionComparatorBox} from './ConditionComparatorBox';
import {isNumeric} from '../../../util/stringUtils';

interface Props {
    conditions: RecipientCondition[];
    addConditionCallback: () => void;
    removeConditionCallback: (index: number) => void;
    conditionChangeCallback: (index: number, conditionToChange: RecipientCondition) => void;
    submitAttempted: boolean;
}

export const ConditionsView = (props: Props) => {

    const handleApiChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const conditionToChange = props.conditions.at(index);
        if (conditionToChange) {

            if (acceptableApiNames.includes(event.target.value)) {
                conditionToChange.apiName = event.target.value as ApiConditionTarget;
            } else {
                conditionToChange.apiName = undefined;
            }

            switch (conditionToChange.apiName) {
                case 'candidateStatus':
                case 'tempType':
                case 'extensionRecruiterStage':
                case 'status':
                case 'candidateFirstName':
                case 'contactNGReferralSource':
                case 'contactDiscipline':
                    conditionToChange.apiValue = '' as string;
                    conditionToChange.dateComparator = undefined;
                    break;
                case 'extension':
                case 'terminated':
                case 'contactAcceptanceOfAgreement':
                    conditionToChange.apiValue = true;
                    conditionToChange.dateComparator = undefined;
                    break;
                case 'numberOfWeeksBooked':
                case 'numberOfPlacements':
                    conditionToChange.apiValue = 0;
                    conditionToChange.dateComparator = undefined;
                    break;
                case 'tempAddressLastUpdate':
                case 'placementStart':
                case 'placementEnd':
                case 'contactCreatedDate':
                case 'contactLastActivityByRecruiter':
                    conditionToChange.apiValue = 0;
                    conditionToChange.dateComparator = 'before';
            }

            props.conditionChangeCallback(index, conditionToChange);
        }
    };

    const handleComparatorChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const conditionToChange = props.conditions.at(index);
        if (conditionToChange) {
            if (event.target.value.includes('after') || event.target.value.includes('before')) {
                conditionToChange.dateComparator = event.target.value as DateComparators;
            }
            else {
                conditionToChange.apiComparator = event.target.value as ApiComparators;
            }
            props.conditionChangeCallback(index, conditionToChange);
        }
    };

    const handleApiValueChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
                                  index: number) => {
        const conditionToChange = props.conditions.at(index);
        if (conditionToChange) {
            switch (conditionToChange.apiName) {
                case 'candidateStatus':
                case 'tempType':
                case 'extensionRecruiterStage':
                case 'status':
                case 'candidateFirstName':
                case 'contactNGReferralSource':
                case 'contactDiscipline':
                    conditionToChange.apiValue = event.target.value as string;
                    break;
                case 'extension':
                case 'terminated':
                case 'contactAcceptanceOfAgreement':
                    conditionToChange.apiValue = (event.target.value === 'true') as boolean;
                    break;
                case 'numberOfWeeksBooked':
                case 'numberOfPlacements':
                case 'tempAddressLastUpdate':
                case 'placementStart':
                case 'placementEnd':
                case 'contactCreatedDate':
                case 'contactLastActivityByRecruiter':
                    if (event.target.value === '' || !isNumeric(event.target.value)) {
                        conditionToChange.apiValue = event.target.value;
                    } else {
                        conditionToChange.apiValue = parseInt(event.target.value) as number;
                    }
            }

            props.conditionChangeCallback(index, conditionToChange);
        }
    };

    return (
        <Container fluid>
            <Row md={'auto'}>
                <Col md={'auto'} style={{width: '100%'}}>
                    <Row className={'trigger-rows'}>
                        <label>Recipient Selection Conditions</label>
                    </Row>
                    {props.conditions.length === 0 ? <span>This Journey currently has no conditions.</span> : <></>}
                    <div style={{display: 'flex'}}>
                        <Form style={{width: '100%'}}>
                            {props.conditions.map((c, index) => (
                                <Row md={'auto'} style={{marginBottom: '15px'}}>
                                    <Col style={{display: 'flex', alignItems: 'center'}}>
                                        <CloseButton onClick={() => props.removeConditionCallback(index)}/>
                                    </Col>
                                    <Col>
                                        <FormSelect
                                            onChange={(e) => handleApiChange(e, index)}
                                            value={c.apiName}
                                            isInvalid={c.apiName === undefined && props.submitAttempted}
                                        >
                                            <option value={'undefined'}>Select a Condition</option>
                                            <optgroup label={'Salesforce - Candidate'}>
                                                <option value={'candidateFirstName'}>First Name</option>
                                                <option value={'numberOfPlacements'}>Number of Placements</option>
                                                <option value={'candidateStatus'}>Candidate Status</option>
                                                <option value={'tempAddressLastUpdate'}>Temp Address Last Update
                                                </option>
                                                <option value={'tempType'}>Temp Type</option>
                                                <option value={'contactCreatedDate'}>Created Date</option>
                                                <option value={'contactNGReferralSource'}>NG Referral Source</option>
                                                <option value={'contactLastActivityByRecruiter'}>Last Activity By
                                                    Recruiter
                                                </option>
                                                <option value={'contactDiscipline'}>Discipline</option>
                                                <option value={'contactAcceptanceOfAgreement'}>Acceptance Of Agreement
                                                </option>
                                            </optgroup>
                                            <optgroup label={'Salesforce - Placement'}>
                                                <option value={'placementStart'}>Start Date</option>
                                                <option value={'placementEnd'}>End Date</option>
                                                <option value={'extensionRecruiterStage'}>Extension Recruiter Stage
                                                </option>
                                                <option value={'extension'}>Extension</option>
                                                <option value={'status'}>Placement Status</option>
                                                <option value={'terminated'}>Terminated</option>
                                                <option value={'numberOfWeeksBooked'}>Number of Weeks Booked</option>
                                            </optgroup>
                                        </FormSelect>
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a selection condition.
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <ConditionComparatorBox
                                            condition={c}
                                            handleComparatorChange={handleComparatorChange}
                                            index={index}
                                        />
                                    </Col>
                                    <Col>
                                        {c.apiName === undefined ? null :
                                            <ConditionInputBox
                                                condition={c}
                                                handleApiValueChange={(e) => handleApiValueChange(e, index)}
                                                index={index}
                                            />
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </Form>
                    </div>
                    <Row md={'auto'} className={'trigger-rows'}>
                        <span>
                            <Button size={'sm'} variant={'success'}
                                    onClick={props.addConditionCallback}>Add condition</Button>
                        </span>
                    </Row>
                    <Row className={'trigger-rows'}>
                        <small>Note: The message will be sent to any recipients that meet ALL of the above
                            conditions.</small>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};