import './ReportView.css';
import {Col, FormControl, FormSelect, Row, Stack} from 'react-bootstrap';
import {BackButton} from '../BackButton/BackButton';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {DynamicWidthInput} from '../util/DynamicWidthInput';
import {fetchJourneys} from '../../api';
import {Journey} from '../../types/Journey';
import {formatDateString} from '../../util/dateUtils';
import {HorizontallyCenteredSpinner} from '../util/HorizontallyCenteredSpinner';
import {OverlayToast} from '../OverlayToast/OverlayToast';
import {Message} from '../../types/Message';

interface Props {

}

export const ReportView = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [showLoadingError, setShowLoadingError] = useState<boolean>(false);
    
    const [journeys, setJourneys] = useState<Journey[]>();
    
    const [selectedJourney, setSelectedJourney] = useState<Journey>();
    const [searchString, setSearchString] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    
    useEffect(() => {
        fetchJourneysAsync().catch((err) => {
            setShowLoadingError(true);
            setLoading(false);
            window.setTimeout(() => setShowLoadingError(false), 5000);
        });
    }, []);

    const fetchJourneysAsync = async () => {
        setLoading(true);
        const response = await fetchJourneys();
        if (response.status === 200) {
            let journeys = response.data as Journey[];
            setJourneys(journeys);
            setLoading(false);
        }
    };
    
    const messageSortingPredicate = (msg1: Message, msg2: Message) => {
        const message1 = new Date(msg1.createdTimestamp);
        const message2 = new Date(msg2.createdTimestamp);
        
        if (message1 <= message2) {
            return -1;
        }
        else {
            return 1;
        }
    };
    
    const getJourneyByGuid = (guid: string) => {
        return journeys?.find(j => j.journeyGuid === guid);
    };
    
    return (
        <div id="sms-container">
            <Stack gap={2}>
                <Row md={'auto'} className={'align-items-center'}>
                    <Col>
                        <BackButton/>
                    </Col>
                    <Col>
                        <h1 className={'max-header'}>JOURNEY REPORTS</h1>
                    </Col>
                </Row>
                <Row className='whitebox-reports' md={'auto'}>
                    <Col>
                        <label style={{margin: 0}}>Journey Name*</label>
                        <FormSelect
                            value={selectedJourney?.journeyGuid}
                            onChange={(e) => {
                                setSelectedJourney(getJourneyByGuid(e.target.value.toString()));
                            }}
                        >
                            <option value={'undefined'}>Select a Journey</option>
                            {
                                journeys?.map((journey) => (
                                    <option value={`${journey.journeyGuid}`}>{journey.journeyName}</option>
                                ))
                            }
                        </FormSelect>
 
                    </Col>
                    <Col md={{ offset: 1 }} >
                        <label style={{margin: 0}}>Search</label>
                        <DynamicWidthInput
                            value={searchString}
                            minWidth={300}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value.toString())}
                        />
                        
                    </Col>
                    <Col>
                        <label style={{margin: 0}}>Start Date</label>
                        <FormControl
                            type={'date'}
                            as={'input'}
                            value={formatDateString(startDate)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setStartDate(e.target.value ? new Date(e.target.value) : undefined);
                            }}
                        />
                    </Col>
                    <Col>
                        <label style={{margin: 0}}>End Date</label>
                        <FormControl
                            type={'date'}
                            as={'input'}
                            value={formatDateString(endDate)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value ? new Date(e.target.value) : undefined)}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        loading ? <HorizontallyCenteredSpinner showLoadingText={true}/> :
                        !selectedJourney ? <h3 className={'no-messages-indicator'}>No journey selected.</h3> :
                            (!selectedJourney.messages || !selectedJourney.messages.length) ?
                                <h3 className={'no-messages-indicator'}>There are no messages available for this journey.</h3> :
                                <>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>Journey Name</th>
                                            <th>Recipient Name</th>
                                            <th>Date Sent</th>
                                            <th>Time Sent</th>
                                        </tr>
                                        {
                                            selectedJourney?.messages?.sort(messageSortingPredicate).map(message => {
                                                    const timestamp = new Date(message.createdTimestamp);
                                                    const timestampDate = new Date(timestamp.toDateString());
                                                    const modifiedEndDate = new Date((endDate?.getTime() ?? 0) + (1000 * 60 * 60 * 24));

                                                    let matchesSearch: boolean = message.recipient.recipientName.toLowerCase().includes(searchString.trim().toLowerCase());
                                                    let matchesStartDate: boolean = !startDate || timestampDate >= startDate;
                                                    let matchesEndDate: boolean = !endDate || timestampDate <= modifiedEndDate;

                                                    if (!matchesSearch || !matchesEndDate || !matchesStartDate) {
                                                        return null;
                                                    }
                                                    return (
                                                        <tr>
                                                            <td>{selectedJourney?.journeyName}</td>
                                                            <td>{message?.recipient?.recipientName ?? 'Recipient Unavailable'}</td>
                                                            <td>{(new Date(message?.createdTimestamp)).toDateString() ?? 'Date Unavailable'}</td>
                                                            <td>{(new Date(message?.createdTimestamp)).toTimeString() ?? 'Time Unavailable'}</td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    <div className="rectangle"></div>
                                </>
                    }
                </Row>
            </Stack>
            <OverlayToast
                show={showLoadingError}
                headerText={'Error!'}
                bodyText={'Something went wrong!'}
                bg={'danger'}
            />
        </div>
    );
};