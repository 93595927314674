import {Col, FormSelect, Row} from 'react-bootstrap';
import {ChangeEvent} from 'react';
import {RecipientCondition} from '../../../types/RecipientCondition';

interface Props {
    condition: RecipientCondition;
    handleComparatorChange: (e: ChangeEvent<HTMLSelectElement>, index: number) => void;
    index: number;
}

export const ConditionComparatorBox = (props: Props) => {

    if (props.condition.apiName === undefined) {
        return null;
    }

    return (
        <Row>
            {['placementStart', 'placementEnd', 'tempAddressLastUpdate', 'contactCreatedDate', 'contactLastActivityByRecruiter'].includes(props.condition.apiName) ?
                <>
                    <Col style={{padding: '0'}}>
                        <FormSelect
                            onChange={(e) => props.handleComparatorChange(e, props.index)}
                            value={props.condition.dateComparator}
                            style={{minWidth: '135px'}}
                        >
                            <option value={'before'}>Days until</option>
                            <option value={'after'}>Days ago</option>
                        </FormSelect>
                    </Col>
                    <Col style={{paddingRight: '0'}}>
                        <FormSelect
                            onChange={(e) => props.handleComparatorChange(e, props.index)}
                            value={props.condition.apiComparator}>
                            <option value={'<'}>&lt;</option>
                            <option value={'<='}>&lt;=</option>
                            <option value={'='}>=</option>
                            <option value={'>='}>&gt;=</option>
                            <option value={'>'}>&gt;</option>
                            <option value={'!='}>!=</option>
                        </FormSelect>
                    </Col>
                </>
                :
                <FormSelect
                    onChange={(e) => props.handleComparatorChange(e, props.index)}
                    value={props.condition.apiComparator}>
                    <option value={'<'}>Less than</option>
                    <option value={'<='}>Less than or equal to</option>
                    <option value={'='}>Equals</option>
                    <option value={'>='}>Greater than or equal to</option>
                    <option value={'>'}>Greater than</option>
                    <option value={'!='}>Does not equal</option>
                </FormSelect>
            }
        </Row>
    );
};