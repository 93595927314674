import {RecipientCondition} from '../../../types/RecipientCondition';
import {ChangeEvent} from 'react';
import {DynamicWidthInput} from '../../util/DynamicWidthInput';
import {Form, FormControl, FormSelect} from 'react-bootstrap';
import {isNumeric} from '../../../util/stringUtils';

interface Props {
    condition: RecipientCondition;
    handleApiValueChange: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>, index: number) => any;
    index: number;
}

export const ConditionInputBox = (props: Props) => {

    if (props.condition.apiName === undefined) {
        return null;
    }

    return (
        <>
            {['candidateFirstName', 'candidateStatus', 'tempType', 'extensionRecruiterStage', 'status', 'contactNGReferralSource', 'contactDiscipline']
                .includes(props.condition.apiName) ?
                <DynamicWidthInput
                    value={props.condition.apiValue as string}
                    minWidth={194}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleApiValueChange(e, props.index)}
                />
                :
                <></>
            }
            {['placementStart', 'placementEnd', 'tempAddressLastUpdate', 'contactCreatedDate', 'contactLastActivityByRecruiter'].includes(props.condition.apiName) ?
                <>
                    <FormControl
                        onChange={(e) => props.handleApiValueChange(e, props.index)}
                        as={'input'}
                        value={props.condition.apiValue as string}
                        isInvalid={!isNumeric(props.condition.apiValue as string) && props.condition.apiValue !== 'null'}
                    />
                    <Form.Control.Feedback type="invalid">
                        Invalid value. This value must be a number or "null" for the chosen API field.
                    </Form.Control.Feedback>
                </>
                :
                <></>
            }
            {['extension', 'terminated', 'contactAcceptanceOfAgreement'].includes(props.condition.apiName) ?
                <FormSelect
                    onChange={(e) => props.handleApiValueChange(e, props.index)}
                    value={props.condition.apiValue === true ? 'true' : 'false'}
                >
                    <option value={'true'}>True</option>
                    <option value={'false'}>False</option>
                </FormSelect>
                :
                <></>
            }
            {['numberOfWeeksBooked', 'numberOfPlacements'].includes(props.condition.apiName) ?
                <FormControl
                    onChange={(e) => props.handleApiValueChange(e, props.index)}
                    type={'number'}
                    as={'input'}
                    value={props.condition.apiValue !== 'null' ? props.condition.apiValue as string : ''}
                />
                :
                <></>
            }
        </>
    );
};