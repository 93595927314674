export type ApiConditionTarget = 'candidateFirstName' | 'placementStart' | 'placementEnd' | 'tempType' | 'candidateStatus' |
    'extensionRecruiterStage' | 'extension' | 'terminated' | 'status' | 'numberOfWeeksBooked' | 'numberOfPlacements' |
    'tempAddressLastUpdate' | 'contactCreatedDate' | 'contactNGReferralSource' | 'contactLastActivityByRecruiter' |
    'contactDiscipline' | 'contactAcceptanceOfAgreement' | undefined;

export const acceptableApiNames = [
    'candidateFirstName',
    'placementStart',
    'placementEnd',
    'tempType',
    'candidateStatus',
    'extensionRecruiterStage',
    'extension',
    'terminated',
    'status',
    'numberOfWeeksBooked',
    'numberOfPlacements',
    'tempAddressLastUpdate',
    'contactCreatedDate',
    'contactNGReferralSource',
    'contactLastActivityByRecruiter',
    'contactDiscipline',
    'contactAcceptanceOfAgreement'
];