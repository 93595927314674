import {Trigger} from './Trigger';
import {RecipientCondition} from './RecipientCondition';
import {User} from './User';
import {Message} from './Message';

export interface Journey {
    journeyId: number;
    journeyGuid: string;
    journeyName: string;
    journeyDescription: string;
    journeyAuthor: User;
    status: 'Active' | 'Paused';
    creationDate: Date;
    journeyStart?: Date | null;
    journeyEnd?: Date | null;
    replyEmails: string[];
    deferToWeekend: boolean;
    messageMedium: 'Sms' | 'Email';
    messages: Message[];
    triggers: Trigger[];
    recipientConditions: RecipientCondition[];
    messageBody: string;
    sundayStartTime?: string;
    sundayEndTime?: string;
    mondayStartTime?: string;
    mondayEndTime?: string;
    tuesdayStartTime?: string;
    tuesdayEndTime?: string;
    wednesdayStartTime?: string;
    wednesdayEndTime?: string;
    thursdayStartTime?: string;
    thursdayEndTime?: string;
    fridayStartTime?: string;
    fridayEndTime?: string;
    saturdayStartTime?: string;
    saturdayEndTime?: string;
}

export const constructJourneyListFromAPIResponse = (apiData: any): Journey[] => {
    // Date objects were deserializing as a string, need to manually deserialize to a date object when fetching from the API
    const returnList: Journey[] = [];
    for (const journey of apiData) {
        const newJourney = {...journey} as Journey;
        returnList.push(handleTypeCasting(newJourney));
    }

    return returnList;
};

export const constructJourneyFromAPIResponse = (apiData: any): Journey => {
    // Date objects were deserializing as a string, need to manually deserialize to a date object when fetching from the API
    const newJourney = {...apiData} as Journey;

    return handleTypeCasting(newJourney);
};

const handleTypeCasting = (newJourney: Journey): Journey => {
    if (newJourney.creationDate) {
        newJourney.creationDate = new Date(newJourney.creationDate);
    }

    if (newJourney.journeyStart) {
        newJourney.journeyStart = new Date(newJourney.journeyStart);
    }

    if (newJourney.journeyEnd) {
        newJourney.journeyEnd = new Date(newJourney.journeyEnd);
    }

    const newTriggerList: Trigger[] = [];
    for (const trigger of newJourney.triggers) {
        const newTrigger = {...trigger};

        if (trigger.onDate) {
            newTrigger.onDate = new Date(trigger.onDate);
        }
        newTriggerList.push(newTrigger);
    }

    newJourney.triggers = newTriggerList;

    const newConditionList: RecipientCondition[] = [];
    for (const condition of newJourney.recipientConditions) {
        const newCondition = {...condition};

        if (condition.apiValue === 'true' || condition.apiValue === 'false') {
            newCondition.apiValue = (condition.apiValue === 'true') as boolean;
        }

        newConditionList.push(newCondition);
    }

    newJourney.recipientConditions = newConditionList;

    return newJourney;
};